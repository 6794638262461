"use client";

import { useRouter } from "next/navigation";
import { Button, Loading } from "@/lib/ui";
import { Ticket, TicketTranslations, OrderType } from "database/*";
import { SALE_STATUS, SALE_STATUS_TYPE } from "@sajilni/config";
import Cookies from "js-cookie";
import useSWRMutation from "swr/mutation";
import { useState } from "react";
import { TicketIcon } from "lucide-react";
import { useTranslation } from "@sajilni/localization";

export interface TicketWithTranslation extends Ticket {
  ticketTranslations: TicketTranslations[];
}
type orderPayload = {
  selectedItems: { ticketId: string; quantity: number }[];
  eventId: string;
  type: OrderType;
};
async function createOrder(
  url: string,
  {
    arg,
  }: {
    arg: orderPayload;
  }
) {
  await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(arg),
  }).then(async (res) => {
    {
      const data = await res.json();
      Cookies.set("orderId", data.result.id);
      return data;
    }
  });
}
export default function BuyTicket({
  eventId,
  eventSlug,
  ticket,
  type,
  saleStatus,
  singleTicket,
  variant = "solid",
  buttonColor = "primary",
  className = "",
}: {
  eventId: string;
  eventSlug: string;
  ticket: TicketWithTranslation | TicketWithTranslation[];
  type: OrderType;
  saleStatus?: string;
  singleTicket?: boolean;
  variant?: "solid" | "outlined" | "ghost" | "link" | undefined;
  buttonColor?: "primary" | "secondary" | "danger" | "gray" | undefined;
  className?: string;
}) {
  const { t } = useTranslation("sajilni2.0");
  const router = useRouter();
  const { trigger, isMutating } = useSWRMutation(
    `${process.env["NEXT_PUBLIC_SAJILNI_ORDER_APP_URL"]}/api/order`,
    createOrder
  );

  const [loading, setLoading] = useState(false);

  const handleBuyTickets = async () => {
    setLoading(true);
    let href = "",
      orderPayload: orderPayload | null = null;

    if (!Array.isArray(ticket)) {
      if (singleTicket && saleStatus == SALE_STATUS.IN_PROGRESS) {
        orderPayload = {
          selectedItems: [
            {
              ticketId: ticket?.id,
              quantity: ticket?.maxAmount || 1,
            },
          ],
          eventId: eventId,
          type: type,
        };

        href = `/checkout/${eventSlug}/ticket-form`;
      } else {
        href = `/checkout/${eventSlug}/ticket-selection?ticketId=${ticket?.id}`;
      }
    } else {
      if (singleTicket && ticket.length === 1) {
        orderPayload = {
          selectedItems: [
            {
              ticketId: ticket[0]?.id,
              quantity: ticket[0]?.minAmount || 1,
            },
          ],
          eventId: eventId,
          type,
        };

        href = `/checkout/${eventSlug}/ticket-form`;
      } else {
        href = `/checkout/${eventSlug}/ticket-selection`;
      }
    }
    if (orderPayload) {
      await trigger(orderPayload);
      setLoading(false);
    }
    router.push(href);
  };
  return (
    <Button
      variant={variant}
      onClick={() => handleBuyTickets()}
      className={className}
      disabled={isMutating || loading}
      style={{ gap: 8 }}
    >
      {loading ? <TicketIcon className="text-white h-5 w-5" />: null}
      {loading ? <Loading /> : null}    <span className="text-white">{t("Buy Ticket")}</span>
    </Button>
  );
}
