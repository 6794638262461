"use client";
import React, { useEffect, useState } from "react";
import { useParams, useSelectedLayoutSegments } from "next/navigation";

import { switchLocaleAction } from "../fetchers/switch-locale";
import { useTranslation } from "../i18n/client";
// We removed the `locale` prop because we can get it from the hook
export function ChangeLocale() {
  const { i18n } = useTranslation("common");
  // You can also use our custom hook instead of `i18n.resolvedLanguage`
  // const locale = useLocale();
  const segments = useSelectedLayoutSegments();
  const { id } = useParams() as { id?: string };

  const [hideLayout, sethideLayout] = useState<boolean>(false);
  useEffect(() => {
    if (segments.includes("event") && id && segments.includes("venu")) {
      sethideLayout(true);
    }
  }, [segments]);

  return !hideLayout ? (
    <div>
      <select
        onChange={async (e) => {
          await switchLocaleAction(e.target.value);
        }}
        value={i18n.resolvedLanguage}
      >
        <option value="en">🇺🇸 English</option>
        <option value="ar">🇸🇦 العربية</option>
      </select>
    </div>
  ) : null;
}
