export const SERVICES_URLS = {
  MAIN_APP: process.env.NEXT_PUBLIC_SAJILNI_MAIN_APP,
  ORDER_APP: process.env.NEXT_PUBLIC_SAJILNI_ORDER_APP,
  OLD_AUTH_APP: process.env.NEXT_PUBLIC_EVENT_EXPERIENCE_DEV_URL,
  FORM_BUILDER_APP: process.env.NEXT_PUBLIC_FORM_BUILDER_APP,
  NOTIFICATION_APP: process.env.NEXT_PUBLIC_NOTIFICATION_APP,
  PAYMENT_APP: process.env.NEXT_PUBLIC_PAYMENT_APP,
  MALGA_VIRTUAL: process.env.NEXT_PUBLIC_MALGA_VIRTUAL_APP,
  CONTENT_MANGER_APP: process.env.NEXT_PUBLIC_CONTENT_MANGER_APP,
  INTEGRATION_APP: process.env.NEXT_PUBLIC_INTEGRATION_APP,
  SECRET_HASH_PASSWORD: process.env.NEXT_PUBLIC_SECRET_HASH_PASSWORD,
  AE_SAJILNI: process.env.AE_SAJILNI,
  TASHBEEK_APP: process.env.NEXT_PUBLIC_TASHBEEK_APP,
  MERGETAGS_BASE_API: process.env.NEXT_PUBLIC_MERGETAGS_BASE_API,
  NEXT_PUBLIC_SECRET_HASH_PASSWORD:
    process.env.NEXT_PUBLIC_SECRET_HASH_PASSWORD,
  LEGACY_APP: process.env.NEXT_PUBLIC_LEGACY_APP,
} as const;

export enum SALE_STATUS {
  "IN_PROGRESS" = "In progress",
  "SALES_ENDED" = "Sales End",
  "COMING_SOON" = "Up coming",
}
export type SALE_STATUS_TYPE = "In progress" | "Sales End" | "Up coming";

export enum CHECK_IN_OUT {
  CHECK_OUT = "CHECK_OUT",
  CHECK_IN = "CHECK_IN",
}

export * from "./Invitation";
export * from "./Transition";
