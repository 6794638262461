"use client";
import Link from "next/link";
import type { ReactNode } from "react";
import type { WhiteLabel } from "database";
import SajilniLogo from "@/components/SajilniLogo";
import { useTranslation } from "@sajilni/localization";
import BuyTicket from "@/components/BuyTicket";
import { useSelectedLayoutSegments } from "next/navigation";
import { OrderType } from "database";
import { cn } from "@sajilni/utils";

export default function DefaultLayout({
  whiteLabel,
  children,
  event,
}: {
  whiteLabel: WhiteLabel;
  children: ReactNode;
  event: any;
}) {
  const { t } = useTranslation("sajilni2.0");

  const segments = useSelectedLayoutSegments();
  const PAGES = {
    EVENT: "event",
    EXHIBITOR: "exhibitor",
    EXHIBITOR_RESERVE: "exhibitorReserve",
  };
  return (
    <>
      {!segments.includes("auth") &&
        !segments.includes(PAGES.EXHIBITOR) &&
        !segments.includes(PAGES.EXHIBITOR_RESERVE) && (
          <div className="sticky top-0 z-30 flex h-[108px] bg-white/30 shadow backdrop-blur-sm">
            <div className="xs:px-3 mx-auto flex h-full w-full items-center justify-between sm:px-6 lg:px-16 2xl:max-w-screen-xl">
              <Link href="/" className="flex items-center justify-center">
                <div className="items-center justify-center  align-middle">
                  {/* <Image
                height={50}
                width={50}
                alt={whiteLabel.name || ""}
                src={whiteLabel.logo || ""}
                className="transition-transform hover:scale-110"
              /> */}
                  <SajilniLogo width={122} height={32} />
                </div>
                {/* <span className="ml-2 inline-block truncate text-sm font-medium">
              {whiteLabel.name}
            </span> */}
              </Link>

              <div className="inline-flex space-x-3">
                {segments[0] === PAGES.EVENT &&
                event?.tickets?.length > 0 &&
                event.published == true ? (
                  <BuyTicket
                    eventId={event.id}
                    eventSlug={event.slug}
                    ticket={event.tickets}
                    singleTicket={event?.options?.autoSkip}
                    className="xs:mt-2 bg-primary-600 gap-2 text-base font-medium"
                    type={OrderType.Ticket}

                  />
                ) : null}
                {/* <Button variant="ghost" size="icon">
              <Bell className="h-5 w-5" />
            </Button> */}
                {/* <UserNavAvatar /> */}
              </div>
            </div>
          </div>
        )}

      <main
        className={cn(
          "mx-auto", segments.includes(PAGES.EXHIBITOR) || segments.includes("auth") ||  segments.includes(PAGES.EXHIBITOR_RESERVE) ? "h-full": "h-[calc(100vh-64px)] md:px-6 md:py-16 lg:px-16 2xl:max-w-screen-xl"
        )}
      >
        {children}
      </main>
    </>
  );
}
