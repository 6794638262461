import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sajilni2/web/sajilni2.0/apps/sajilni2.0/src/components/layouts/default-layout/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/packages/sajilni-localization/src/components/ChangeLocale.tsx");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/packages/sajilni-localization/src/components/FormTranslater.tsx");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/packages/sajilni-localization/src/components/LanguageInputSelectore.tsx");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/packages/sajilni-localization/src/components/LanguagesPreview.tsx");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/packages/sajilni-localization/src/components/LanguageSwitcher.tsx");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/packages/sajilni-localization/src/hooks/locale-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/packages/sajilni-localization/src/i18n/client.ts");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/packages/sajilni-localization/src/styles.css");
