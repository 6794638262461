export const VALIDATION_RULES = {
  isEmail:
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  isNumber: /^\d+$/,
  isCharacters: /^[a-z A-Z ء-ي]+$/, // even arabic characters allowed
  isPassword:
    /^(?=.*[a-zA-Z])(?=.*[#$@!%&*?])(?=.*\d)[A-Za-z\d#$@!%&*?]{8,30}$/,
  // Min 1 uppercase letter, min 1 lowercase letter , min 1 special character, min 1 number, min 8 characters ,max 30 characters.
  isPhoneNumber: /^(\+?\d{1,4})\s\d{7,14}$/, // Matches international phone numbers with country code
} as const;
export const MAX_FILE_SIZE = 500000;
export const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];
